import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductCardList from "../components/product-card-list";
import Breadcrumbs from "../components/breadcrumbs";
import ReactMarkdownWithHtml from "react-markdown"

function AuthorTemplate({ pageContext: { author }, data }) {
    const products = data.Directus.Product || [];
    const lsJson = [];
    const breadcrumbs = [
        { name: "Autores", link: "/autor/" },
        { name: author.Name, link: `/autor/${author.Slug}` }
    ];

    const personLd = {
        "@context": "http://schema.org",
        "@type": "person",
        "name": author.Name,
        "sameAs": author.PersonalPageLink
    };
    lsJson.push(personLd);

    const breadcrumbsLd = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": breadcrumbs.map((item, index) => {
          return {
            "@type": "ListItem",
            "position": index + 1,
            "name": item.name,
            "item": item.link
          }
        })
    };
    lsJson.push(breadcrumbsLd);


    return (
        <Layout>
            <SEO title={author.Name} ldJson={lsJson} description={author.Description} keywords={author.MetaKeywords} />
            <Breadcrumbs items={breadcrumbs} />
            <h1>{author.Name}</h1>
            <div className="flex flex-col md:flex-row pb-14">
                <div className="w-full md:w-12/12 mt-4 md:mt-0 md:ml-1">
                    <p className="leading-normal pt-2">
                    <ReactMarkdownWithHtml children={author.Description} />
                    </p>
                </div>
            </div>
            <h2>Livros escritos por {author.Name}</h2>
            <ProductCardList products={products} />
        </Layout>
    )
}



export const query = graphql`
    query BooksByAuthor($authorId: Float) {
        Directus {
            Product(filter: {Author: {Author_Id: {Id: {_eq: $authorId}}}}) {
                Name
                Slug
                MetaDescription
                Image {
                    filename_disk
                }
                SubCategoryId {
                    Slug
                    Name
                    CategoryId {
                        Name
                        Slug
                    }
                }
            }
        }
    }
    `

export default AuthorTemplate